import httpClient, { getData } from "@/api/http-client";
import { shipments } from '@/utilities/statuses.const';
import { getTrend } from '@/utilities';
import camelcaseKeys from 'camelcase-keys';

const END_POINT = '/dashboard/operational';


const getShipment = async ({convert = true, path,  params = {}}) =>  { //customer_number
    const res = await httpClient.get( END_POINT + path, {params});
    const result = {};
    if (res.status === 200 && convert) {
        const data = res.data;
        result.total = data.total;
        result.lastTotal = data['last_day_total'];
        const trend = getTrend(data.total, result.lastTotal);
        result.trend = (trend < 0 ? "" : "+") + trend + "%";
        result.statuses = shipments.getArrayStatuses(data);
        return result
    }
    return res;
}

const getTotalActivity = async (params, convert = true) =>  {
    return getShipment({convert, path: '/total-activity', params})
}

const getDirectShipment = (params, convert = true) =>  {
    return getShipment({convert, path: '/direct-shipment', params})
}

const getDropShipment = async (params, convert = true) =>  {
    return getShipment({convert, path: '/drop-shipment', params})
}

const shipmentDetails = {
    "arrive":  {
        title: 'Will Arrive Today',
        footer: 'Total Incoming Progress',
        type: 'incoming',
        total: 0,
        items_total: 0,
        incoming_items_total: 0,
    },
    "ship":  {
        title: 'Will Ship Today',
        footer: 'Total Outgoing Progress',
        type: 'outgoing',
        total: 0,
        items_total: 0,
        incoming_items_total: 0,
    },
}

const getShipmentDetails = async (params) =>  {
    const data = await getData( {path: END_POINT +  '/factory-shipment', params });
    return camelcaseKeys({
        progress: Object.keys(data).map( key => ({
            ...(shipmentDetails[key] || {}),
            ...(data[key] || {})
        }))
    }, {deep: true})
}

const getLocations = async ( params = {}, convert = true) =>  {
    const res = await httpClient.get( END_POINT + '/by-location', {params});
    const result = {};
    if (res.status === 200 && convert) {
        result.statuses = [];
        Object.keys(res.data).forEach((key) => {
            const item = res.data[key];
            result.statuses.push({
                value: item.quantity,
                text: item.title,
            })
        });
        //console.log('getLocations', result);
        return result
    }
    return res;
}


export {
    getTotalActivity,
    getDirectShipment,
    getDropShipment,
    getShipmentDetails,
    getLocations
}

getShipmentDetails().then((data)=> {
    console.log('getShipmentDitails', data);
})