<template>
  <div>
    <v-row>
      <v-col cols="12" lg="4" md="4" sm="6" class="pb-2">
        <card-magic :loading="loading.revenue" title="Revenue" :value="revenue" :min-height="350"/>
      </v-col>

      <v-col cols="12" lg="4" md="4" sm="6" class="pb-2">
        <card-magic :loading="loading.costOfGoods" title="Cost of Goods" :value="costOfGoods" :min-height="350"/>
      </v-col>

      <v-col cols="12" lg="4" md="4" sm="6" class="pb-2">
        <card-magic :loading="loading.profitability" title="Profitablility" :value="profitability" :min-height="350"/>
      </v-col>

      <v-col cols="12" lg="4" md="4" sm="6" class="pb-2">
        <card-magic :loading="loading.invoicesIssued" title="No. of Invoices Issued" trend-text="Invoices are" :value="invoicesIssued" :min-height="350"/>
      </v-col>

      <v-col cols="12" lg="4" md="4" sm="6" class="pb-2">
        <card-magic :loading="loading.vendorBills" title="Vendor Bills" trend-text="Bills are" :value="vendorBills" :min-height="350"/>
      </v-col>

      <v-col cols="12" lg="4" md="4" sm="6">
        <card-magic :loading="loading.stockValue" title="Stock value" :value="stockValue" :min-height="350"/>
      </v-col>
    </v-row>

  </div>
</template>

<script>

import CardMagic from '@/components/ui/cards/CardMagic';
import { gerRevenue, costOfGoods, getProfitability, getInvoicesIssued, getVendorBills, getStockValue } from "@/api/dashboard/finance.api.js";

export default {
  name: 'DashboardTabFinance',
  components: {
    CardMagic
  },
  data: () => ({
    loading: {
      revenue: true,
      costOfGoods: true,
      profitability: true,
      invoicesIssued: true,
      vendorBills: true,
      stockValue: true,
    },
    revenue: {},
    costOfGoods: {},
    profitability: {},
    invoicesIssued: {},
    vendorBills: {},
    stockValue: {},
  }),
  watch: {
    '$store.state.query.params': {
      immediate: true,
      handler:function() {
        if (this.$route.name !== 'dashboard-finance')
          return;
        this.update();
      }
    }
  },
  methods: {
    update() {
      this.loading.revenue = true;
      const params = this.$store.getters.params;
      gerRevenue(params).then((data)=>{
        this.revenue = data;
        this.loading.revenue = false;
      })
      this.loading.costOfGoods = true;
      costOfGoods(params).then((data)=>{
        this.costOfGoods = data;
        this.loading.costOfGoods = false;
      })
      this.loading.profitability = true;
      getProfitability(params).then((data)=>{
        this.profitability = data;
        this.loading.profitability = false;
      })
      this.loading.invoicesIssued = true;
      getInvoicesIssued(params).then((data)=>{
        this.invoicesIssued = data;
        this.loading.invoicesIssued = false;
      })
      this.loading.vendorBills = true;
      getVendorBills(params).then((data)=>{
        this.vendorBills = data;
        this.loading.vendorBills = false;
      })
      this.loading.stockValue = true;
      getStockValue(params).then((data)=>{
        this.stockValue = data;
        this.loading.stockValue = false;
      })
    }
  }
}
</script>
