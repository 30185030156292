const shipmentStatus = {
    UNDEFINED: 0,
    READY_TO_SHIP: 1,
    CHANGE_SHIPMENT_DATE: 2,
    AWAITING_MATERIAL: 3,
    PRODUCTION_DELAY: 4,
    FAILED_QC: 5,
    READY: 6,
    PENDING: 7,
    RISK: 8,
    SHIPPED: 9,
    IN_PROCESS: 10,
}

export const shipments = {
    status: shipmentStatus,
    titles: {
        // [shipmentStatus.UNDEFINED]: 0,
        [shipmentStatus.READY_TO_SHIP]: 'Ready to Ship',
        [shipmentStatus.IN_PROCESS]: 'In process',
        // [shipmentStatus.CHANGE_SHIPMENT_DATE]: 2,
        // [shipmentStatus.AWAITING_MATERIAL]: 3,
        // [shipmentStatus.PRODUCTION_DELAY]: 4,
        // [shipmentStatus.FAILED_QC]: 5,
        [shipmentStatus.READY]: 'Ready',
        [shipmentStatus.PENDING]: 'Pending',
        [shipmentStatus.RISK]: 'At Risk',
        [shipmentStatus. SHIPPED]: 'Shipped',
    },
    getArrayStatuses(apiData) {
        const result = [];
        const $this = this;
        Object.keys(apiData).forEach((key) =>{
            const KEY = key.toUpperCase();
            if (shipmentStatus[KEY]) {
                result.push({
                    value: apiData[key],
                    status: shipmentStatus[KEY],
                    text: $this.titles[shipmentStatus[KEY]],
                });
            }
        })
        return result;
    },
    colorValue: {
        [shipmentStatus.READY_TO_SHIP]: '#32AFAB'
    },
    colorText: {
        [shipmentStatus.READY_TO_SHIP]: '#32AFAB',
        [shipmentStatus.READY]: '#32AFAB',
        [shipmentStatus.PENDING]: '#FF7A00',
        [shipmentStatus.IN_PROCESS]: '#FF7A00',
        [shipmentStatus.RISK]: '#F54747',
        [shipmentStatus.SHIPPED]: '#2D9CDB'
    }
}