<template>
  <div>
    <v-row>
      <v-col cols="12" lg="4" md="4" sm="6" class="pb-2">
        <card-magic
            :loading="loading.totalSales"
            title="Total sales"
            :value="totalSales"
            trend-only-text="Last 7 days"
            :min-height="200" />
      </v-col>

      <v-col cols="12" lg="4" md="4" sm="6" class="pb-2">
        <card-magic  :loading="loading.madeOrder"  title="Made to Order" :value="madeOrder" trend-only-text="Last 7 days" :min-height="200"/>
      </v-col>

      <v-col cols="12" lg="4" md="4" sm="12" class="pb-2">
        <card-magic  :loading="loading.pullFromStock"  title="Pull from stock" :value="pullFromStock" trend-only-text="Last 7 days"  :min-height="200"/>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" lg="4" md="4" sm="6">
        <card-magic  :loading="loading.byCustomers"  title="Sales by customers" :value="byCustomers" :min-height="500"/>
      </v-col>

      <v-col cols="12" lg="4" md="4" sm="6">
          <card-magic  :loading="loading.averageOrder"  title="Average order ($)" :value="averageOrder"  :min-height="500" />
      </v-col>

      <v-col cols="12" lg="4" md="4" sm="12">
        <card :style="{ 'min-height': '500px' }">
          <card-header>
            <div class="card-header__title">Sales Trend Analysis</div>
          </card-header>

          <div class="d-flex justify-center align-center mt-auto mb-auto"  v-if="loading.trendAnalysis">
            <v-progress-circular
                                 :width="3"
                                 :size="50"
                                 color="loader"
                                 indeterminate
            />
          </div>
          <template v-else >
    <!--      trend-chart-wrap -->
              <div class="pr-1 pa-sm-3 pl-sm-1 trend-chart-wrap">
                <sales-trend-chart @loading="(status)=> loading.salesTrendAnalysis = status" :dataChart="trendAnalysis"/>
              </div>
          </template>
        </card>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import {
  getAverageOrder,
  getMadeOrder,
  getPullFromStock,
  getSalesByCustomers,
  getTotalSales,
  getTrendAnalysis
} from "@/api/dashboard/sales";

const SalesTrendChart = ()=> import(/* webpackChunkName: "sales-trend-chart" */ '@/views/dashboard/tabSales/SalesTrendChart');
const CardHeader = ()=> import(/* webpackChunkName: "card-header" */ "@/components/ui/card-elements/CardHeader");
import CardMagic from /* webpackChunkName: "card-magic" */ "@/components/ui/cards/CardMagic";
import '@/api/dashboard/sales';

export default {
  name: 'DashboardTabSales',
  components: {
    SalesTrendChart,
    CardHeader,
    CardMagic
  },
  data: () => ({
    loading: {
      totalSales: true,
      madeOrder: true,
      pullFromStock: true,
      byCustomers: true,
      averageOrder: true,
      trendAnalysis: true,
    },
    totalSales: {},
    madeOrder: {},
    pullFromStock: {},
    byCustomers: {},
    averageOrder: {},
    trendAnalysis: {},
    location: {}
  }),
  watch: {
    '$store.state.query.params': {
      immediate: true,
      handler:function() {
        if (this.$route.name !== 'dashboard-sales')
          return;
        this.update();
      }
    }
  },
  methods: {
    setLoading(status) {
      Object.keys(this.loading).forEach((key)=>{
        this.loading[key] = status;
      });
    },
    update() {
      const params = this.$store.getters.params;
      this.setLoading(true);
      getTotalSales(params).then((data)=>{
        console.log('getTotalSales', data);
        this.totalSales = data;
        this.loading.totalSales = false;
      })
      getMadeOrder(params).then((data)=>{
        console.log('getMedeOrder', data);
        this.madeOrder = data;
        this.loading.madeOrder = false;
      })
      getPullFromStock(params).then((data)=>{
        console.log('getPullFromStrock', data);
        this.pullFromStock = data;
        this.loading.pullFromStock = false;
      })
      getSalesByCustomers(params).then((data)=>{
        console.log('getSalesByCustomers byCustomers', data);
        this.byCustomers = data;
        this.loading.byCustomers = false;
      })
      getAverageOrder(params).then((data)=>{
        console.log('getAverageOrder averageOrder', data);
        this.averageOrder = data;
        this.loading.averageOrder = false;
      })
      getTrendAnalysis(params).then((data)=>{
        console.log('getTrendAnalysis!!!', data);
        this.trendAnalysis = data;
        this.loading.trendAnalysis = false;
      })
    }
  }
}
</script>

<style scoped>
.trend-chart-wrap {
  background-color: #fbfbfb;
}
</style>
