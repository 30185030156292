import { getData } from "@/api/http-client";
import {formatCurrency} from "@/utilities";
import { customers } from "@/utilities/customers.const";
import {convertCost, convertTrend, addPercentInTable, convertToTable, convertItemsGraph} from "@/api/dashboard/transform.api";

const END_POINT = '/dashboard/sales';

export const getTotalSales = async (params) => {
    const data = await getData({path: END_POINT + '/total-sales', params});
    return convertCost(convertTrend(data, 'cost',  'last_day_cost'));
}

export const getMadeOrder = async (params) => {
    const data = await getData({path: END_POINT + '/actual-order-sales', params});
    return convertCost(convertTrend(data, 'cost',  'last_day_cost'));
}

export const getPullFromStock = async (params) => {
    const data = await getData({path: END_POINT + '/pfs-order-sales', params});
    return convertCost(convertTrend(data, 'cost',  'last_day_cost'));
}

const convertDonutChar = (data, {currentName = 'items', convertKey='cost'} = {}) =>{
    const items = {
        total: convertKey === 'total' ? data[convertKey] : formatCurrency(data[convertKey]),
        colors: [],
        labels: [],
        series: []
    };
    if (data[currentName])
        Object.keys(data.items).forEach((key)=> {
            const item = data.items[key];
            items.colors.push(customers.getColor(key));
            items.labels.push(item.title);
            items.series.push(item[convertKey]);
        })
    return items;
}

export const getSalesByCustomers = async (params) => {
    const data = await getData( {path: END_POINT + '/total-sales-by-customer', params });
    console.log('data getSalesByCustomers', data);
    return {
        donutChart: convertDonutChar(data),
        headers: [
            {text: '', value: 'title', width: '60%'},
            {text: '(%)', value: 'percent', class:['text-right']},
            {text: 'Total', value: 'cost', class:['text-right']}
        ],
        ...addPercentInTable(convertToTable(data))
    };
}

export const getAverageOrder = async (params) => {
    const data = await getData( {path: END_POINT + '/average-by-customer', params });
    return {
        donutChart: convertDonutChar(data),
        headers: [
            {text: '', value: 'title', width: '60%'},
           // {text: '(%)', value: 'percent', class:['text-right']},
            {text: 'Total', value: 'cost', class:['text-right']}
        ],
        ...convertToTable(data) //addPercentInTable
    };
}

export const getTrendAnalysis = async (params) => {
    const data = await getData( {path: END_POINT + '/trend-analysis', params });
    console.log('getTrendAnalysis getTrendAnalysis', data);
    const resutl =  convertItemsGraph(data);
    console.log('getTrendAnalysis result', resutl);
    return resutl;
}


// export {
//     getTotalSales,
//     getMadeOrder,
//     getPullFromStock,
//     getSalesByCustomers,
//     getAverageOrder,
//     getTrendAnalysis
// }