<template>
  <div :class="{'pa-4 pt-2': wrap && paddings }" :style="{ background: wrapColor }">
    <v-simple-table class="chart-table">
      <template v-slot:default>
        <thead>
        <tr>
          <th   v-for="head in headers"
                :key="head.head"
                class="font-weight-semi-bold"
                :class="head.class"
                :style="{'width': head.width }">{{ head.text }}</th>
        </tr>
        </thead>

        <tbody>
        <tr class="chart-table__row" v-for="(item, i) in items" :key="item.id || i">
            <td v-for="(head, hi) in headers" :key="head.name"
                class="font-weight-semi-bold" :class="head.class">
              <div v-if="hi === 0" class="chart-table-marker" :style="{ backgroundColor:  item.markerColor }"></div>
              {{ item[head.value]  }}
            </td>
        </tr>
        </tbody>

      </template>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  name: "TableList",
  props: {
    items: {
      type: Array,
      default: null
    },
    headers: {
      type: Array,
      default: null
    },
    wrap: {
      type: Boolean,
      default: false
    },
    wrapColor: {
      type: String,
      default: '#FBFBFB'
    },
    paddings: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .chart-table-marker {
    display: inline-block;
    margin-right: 12px;
    width: 12px;
    height: 12px;
    border-radius: 2px;
    vertical-align: middle;
  }
  .chart-table__row td:not(:first-child){
    padding-left: 10px!important;
  }
  @media screen and (max-width: 1200px){
    .chart-table__row td{
      font-size: 0.750rem!important;
    }
  }

  .v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
    font-family: "Nunito", sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    font-size: 1rem !important;
  }
  .v-data-table {
    background-color: transparent !important;
    //color: rgba(0, 0, 0, 0.87);
  }
  .chart-table.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th,
  .chart-table.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row) {
    border: none;
  }
  .chart-table.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background-color: transparent;
  }
  .chart-table.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    color: $main-dark;
  }
  .chart-table.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
  .chart-table.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    padding: 12px 0 0;
    height: auto;
  }
</style>