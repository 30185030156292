import httpClient, { getData } from "@/api/http-client";

const END_POINT = '/dashboard';

export const getCustomers = async ({ convert = true }) => {
   const res = await httpClient.get(END_POINT + '/customers');
   if (res.status === 200 && convert) {
       const result = [{text: 'All', value: ''}];
       return result.concat(res.data.data.map(({number, title})=>({
           value: number,
           text: title
       })))
   }
   return res;
}


export const getListIntervals = async () => {
    const data = await getData({ path: END_POINT + '/intervals'});
    return  Object.keys(data).map( key => ({
        value: key,
        text: data[key]
    }))
}

getListIntervals().then((data)=>{
   console.log('getListIntervals', data);
});