<template>
  <card  :style="{ 'min-height': cardMinHeight }">
    <card-header>
      <div class="card-header__title">{{ title }}</div>
    </card-header>

    <div class="d-flex justify-center align-center mt-auto mb-auto"   v-if="loading">
      <v-progress-circular
                           :width="3"
                           :size="50"
                           color="loader"
                           indeterminate
      ></v-progress-circular>
    </div>

    <template v-else-if="!loading">
      <card-subheader v-if="!value.donutChart && hasSubheader"> <!--class="justify-start"-->
        <div class="big-text">{{ mainValue }}</div>
<!--        mr-2-->
        <div v-if="value.grossProfit" class="big-text">{{ value.grossProfit }}</div>
        <trend v-else-if="value.trend !== undefined" :value="value.trend" :text="trendText" :only-text="trendOnlyText"></trend>
      </card-subheader>

      <template v-if="value.progress && value.progress.length > 0">
        <card-progressbar v-for="item in value.progress" :key="item.title"
                          :title="item.title"
                          :caption="item.footer"
                          :pkg="item.total"
                          :current="item.incomingItemsTotal"
                          :length="item.itemsTotal"
                          :img="require('@/assets/icons/ic-' + item.type + '.svg')"/>
      </template>


      <card-column-line v-if="value.statuses" :separate="separate" :stretch="stretch" :items="value.statuses"></card-column-line>

      <donut-chart v-if="value.donutChart" :chart-data="value.donutChart"/>

      <table-list v-if="value.headers && value.items" wrap
                  :headers="value.headers"
                  :items="value.items"></table-list>
    </template>
  </card>
</template>

<script>
import TableList from '@/components/ui/card-elements/TableList';
import CardSubheader from "@/components/ui/card-elements/CardSubheader";
import CardHeader from "@/components/ui/card-elements/CardHeader";
import Trend from "@/components/ui/card-elements/Trend";
const DonutChart = ()=> import(/* webpackChunkName: "donut-chart" */'@/components/ui/DonutChart');
import CardProgressbar from "@/components/ui/card-elements/CardProgressbar";
import CardColumnLine from "@/components/ui/card-elements/CardColumnLine";

export default {
  name: "CardMagic",
  components: {
    TableList,
    CardSubheader,
    CardHeader,
    Trend,
    DonutChart,
    CardColumnLine,
    CardProgressbar
  },
  props: {
    title: {
      type: String,
      default: "Revenue",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    trend:{
      type: Boolean,
      default: true
    },
    trendText: {
      type: String,
      default: "Trend is",
    },
    trendOnlyText: {
      type: String,
      default: null,
    },
    value: {
      type: Object,
      default: ()=> {}
    },
    separate: {
      type: Boolean,
      default: false
    },
    stretch: {
      type: Boolean,
      default: false
    },
    minHeight: {
      type: [Number, Array],
      default: null
    }
  },
  computed: {
    cardMinHeight() {
      return  this.minHeight + 'px'
    },
    hasSubheader() {
      return this.mainValue !== undefined || this.value.grossProfit !== undefined || this.value.trend !== undefined
    },
    mainValue() {
      if (this.value.total !== undefined && this.value.cost !== undefined)
        return this.value.total + ' | ' + this.value.cost
      if (this.value.total !== undefined)
        return this.value.total;
      if (this.value.cost !== undefined)
        return this.value.cost;
      if (this.value.profit!== undefined)
        return this.value.profit;

      return undefined;
    }
  }
}
</script>

<style scoped>

</style>