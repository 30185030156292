<template>
  <div class="subheader"
       :class="[preset, {'mb-auto': isFinance}]">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "CardSubheader",
  props: {
    value: {
      type: Object,
      default: null,
    },
    paddings: {
      type: Boolean,
      default: true,
    },
    preset: {
      type: String,
      default: 'd-flex flex-wrap align-center'
    }
  },
  computed: {
    isFinance() {
      return this.$route.name === 'dashboard-finance';
    }
  },
}
</script>

<style lang="scss" scoped>
    .subheader {
      justify-content: space-between;
    }
</style>
